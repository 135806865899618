import React, { useState } from 'react';
import axios from 'axios';

function WAXAssetLookup() {
  const [templateId, setTemplateId] = useState('');
  const [owners, setOwners] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const processAssets = (allAssets) => {
    let ownerTally = {};

    allAssets.forEach(asset => {
      const owner = asset.owner || "Burned";
      if (ownerTally[owner]) {
        ownerTally[owner]++;
      } else {
        ownerTally[owner] = 1;
      }
    });

    setOwners(ownerTally);
    setIsLoading(false);
  };

  const fetchAssetData = async (currentPage = 1, allAssets = []) => {
    try {
      const limit = 100;
      const response = await axios.get(`https://wax.api.atomicassets.io/atomicassets/v1/assets?template_id=${templateId}&limit=${limit}&page=${currentPage}`);

      const assets = response.data.data;

      if (assets.length === 0) {
        processAssets(allAssets);
        return;
      }

      allAssets = [...allAssets, ...assets];

      // If we received the maximum amount of results for this page, request the next one
      if (assets.length === limit) {
        fetchAssetData(currentPage + 1, allAssets);
      } else {
        processAssets(allAssets);
      }

    } catch (error) {
      console.error("Error fetching assets:", error);
      setIsLoading(false);
    }
  };

  const searchAssets = () => {
    setOwners({});
    setIsLoading(true);
    fetchAssetData();
  };

  const sortedOwners = Object.entries(owners).sort(([,aCount], [,bCount]) => bCount - aCount);

  return (
    <div>
      <h2>Owners of template</h2>
      <p>Please enter a template ID and click search to start.</p>
      <input
        type="text"
        placeholder="Enter Template ID"
        value={templateId}
        onChange={e => setTemplateId(e.target.value)}
      />
      <button onClick={searchAssets}>Search</button>
      <ul>
        {sortedOwners.map(([owner, count]) => (
          <li key={owner}>
            {owner}: {count}
          </li>
        ))}
      </ul>
      {isLoading && <p><strong>Loading...</strong></p>}
    </div>
  );
}

export default WAXAssetLookup;
