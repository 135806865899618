import React, { useState } from 'react';
import axios from 'axios';

function WAXWalletLookup() {
    const [wallet, setWallet] = useState('');
    const [assets, setAssets] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [collectionInput, setCollectionInput] = useState('');
    const [hideSingleAsset, setHideSingleAsset] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState('all');
    const [collections, setCollections] = useState([]);
    const [collectionCounts, setCollectionCounts] = useState({});
    const [assetCollectionMapping, setAssetCollectionMapping] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const processAssets = (allAssets) => {
        let assetTally = {};
        let collectionTally = {};
        let assetCollectionMap = {};

        allAssets.forEach(asset => {
            const assetName = asset.template?.immutable_data?.name || asset.asset_id;
            const collectionName = asset.collection?.collection_name;

            assetTally[assetName] = (assetTally[assetName] || 0) + 1;
            assetCollectionMap[assetName] = collectionName;
            collectionTally[collectionName] = (collectionTally[collectionName] || 0) + 1;
        });

        setAssets(assetTally);
        setAssetCollectionMapping(assetCollectionMap);
        setCollectionCounts(collectionTally);
        setCollections(Object.keys(collectionTally).sort((a, b) => collectionTally[b] - collectionTally[a]));
        setIsLoading(false);
    };

    const fetchAssetData = async (currentPage = 1, allAssets = []) => {
        try {
            let url = `https://wax.api.atomicassets.io/atomicassets/v1/assets?owner=${wallet}&page=${currentPage}&limit=100&order=desc`;
            if (collectionInput) {
                url += `&collection_name=${collectionInput}`;
            }
            const response = await axios.get(url);

            if (response.data && response.data.data) {
                allAssets = allAssets.concat(response.data.data);
            }

            if (response.data && response.data.data.length === 100) {
                await fetchAssetData(currentPage + 1, allAssets); // Recursive call to handle pagination
            } else {
                processAssets(allAssets); // Process assets after all pages have been fetched
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const searchAssets = () => {
        setIsLoading(true);
        setAssets({});
        setCollections([]);
        setCollectionCounts({});
        setAssetCollectionMapping({});
        fetchAssetData();
    };

    const filteredAssets = Object.entries(assets)
        .filter(([name, count]) => !hideSingleAsset || count > 1)
        .filter(([name]) => !searchTerm || name.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter(([name]) => selectedCollection === 'all' || assetCollectionMapping[name] === selectedCollection)
        .sort(([, aCount], [, bCount]) => bCount - aCount);

    return (
        <div>
            <h2>Assets in WAX Wallet</h2>
            <p>Please enter a WAX Wallet and optionally a collection name, then click search to start.</p>
            <input
                type="text"
                placeholder="Enter WAX Wallet"
                value={wallet}
                onChange={e => setWallet(e.target.value)}
            />
            <input
                type="text"
                placeholder="Enter Collection Name (optional)"
                value={collectionInput}
                onChange={e => setCollectionInput(e.target.value)}
            />
            <button onClick={searchAssets}>Search</button>
            <label>
                <input
                    type="checkbox"
                    checked={hideSingleAsset}
                    onChange={e => setHideSingleAsset(e.target.checked)}
                />
                Hide assets with only 1 in quantity
            </label>
            {isLoading && <p><strong>Loading...</strong></p>}
            {!isLoading && collections.length > 0 && (
                <>
                    <input
                        type="text"
                        placeholder="Search Assets"
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                    />
                    <select
                        value={selectedCollection}
                        onChange={e => setSelectedCollection(e.target.value)}
                    >
                        <option value="all">All Collections</option>
                        {collections.map(collection => (
                            <option key={collection} value={collection}>
                                {collection} ({collectionCounts[collection]})
                            </option>
                        ))}
                    </select>
                </>
            )}
            <ul>
                {filteredAssets.map(([name, count]) => (
                    <li key={name}>
                        {name}: {count}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default WAXWalletLookup;
