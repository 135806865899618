import React from 'react';
import WAXAssetLookup from './WAXAssetLookup';
import WAXWalletLookup from './WAXWalletLookup';
import FloorPriceLookup from './components/FloorPriceLookup';
import './LandingPage.css';

function LandingPage() {
 
  return (
    <div className="landing">
      <header className="landing-header">
        <h1>C3 WAX Tools</h1>
        <p>Tools to help your trip across WAX World</p>
      </header>

      <section className="tool-section">
        <WAXWalletLookup />
      </section>

      <section className="tool-section">
        {/* Make sure to pass the templateId as prop if needed or change the WAXAssetLookup to accept multiple IDs */}
        <WAXAssetLookup templateId="YOUR_TEMPLATE_ID" />
      </section>

      <section className="tool-section">  
        <FloorPriceLookup />
      </section>


      
    </div>
  );
}

export default LandingPage;
