import React, { useState } from 'react';

const IMAGE_BASE_URL = "https://atomichub-ipfs.com/ipfs/";

const computePrices = (salesData, startTimestamp, endTimestamp) => {
    const filteredSales = salesData.filter(
        sale => Number(sale.updated_at_time) >= startTimestamp &&
                Number(sale.updated_at_time) <= endTimestamp &&
                sale.state === 3 //state 3 indicates a sold item
    );

    if (filteredSales.length === 0) {
        return { min: null, max: null, asset: null };
    }

    const prices = filteredSales.map(sale => ({
        price: parseFloat(sale.price.amount) / Math.pow(10, sale.price.token_precision),
        assetData: sale.assets && sale.assets.length > 0 ? sale.assets[0].template.immutable_data : null
    }));

    const minPriceData = prices.reduce((prev, current) => (prev.price < current.price) ? prev : current, { price: Infinity });
    const maxPriceData = prices.reduce((prev, current) => (prev.price > current.price) ? prev : current, { price: 0 });

    // Extract the asset data for the sale with the minimum price
    const assetData = minPriceData.assetData;


    return {
        min: minPriceData.price,
        max: maxPriceData.price,
        asset: assetData // Using asset data from the min priced asset for display
    };
};

const getTimeIntervals = () => {
    const now = new Date().getTime();
    return {
        sixHours: now - 6 * 60 * 60 * 1000,
        oneDay: now - 24 * 60 * 60 * 1000,
        sevenDays: now - 7 * 24 * 60 * 60 * 1000,
        thirtyDays: now - 30 * 24 * 60 * 60 * 1000
    };
};

const fetchAllSalesData = async (assetTemplateId, page = 1, sales = []) => {
    const response = await fetch(`https://wax.api.atomicassets.io/atomicmarket/v2/sales?template_id=${assetTemplateId}&order=desc&page=${page}&limit=100`);
    const data = await response.json();

    if (data.data && data.data.length > 0) {
        sales = [...sales, ...data.data];
        if (data.data.length === 100) {
            return await fetchAllSalesData(assetTemplateId, page + 1, sales);
        }
    }

    return sales;
};

const fetchTemplateData = async (templateId) => {
    const response = await fetch(`https://wax.api.atomicassets.io/atomicassets/v1/templates?template_id=${templateId}`);
    const data = await response.json();
    // Access the first element of the array, as the response is in an array format.
    return data?.data[0] || null;
  };
  
  function FloorPriceLookup() {
      const [templateId, setTemplateId] = useState('');
      const [isLoading, setIsLoading] = useState(false);
      const [prices, setPrices] = useState({});
      const [templateData, setTemplateData] = useState(null);
      const [error, setError] = useState(null);
  
      const handleTemplateIdChange = (e) => {
          setTemplateId(e.target.value);
      };
  
      const fetchPrices = async () => {
          setIsLoading(true);
          setError(null);
  
          try {
              const fetchedTemplateData = await fetchTemplateData(templateId);
              setTemplateData(fetchedTemplateData);
  
              const allSalesData = await fetchAllSalesData(templateId);
              const timeIntervals = getTimeIntervals();
  
              const priceResults = {
                  sixHours: computePrices(allSalesData, timeIntervals.sixHours, Date.now()),
                  oneDay: computePrices(allSalesData, timeIntervals.oneDay, timeIntervals.sixHours),
                  sevenDays: computePrices(allSalesData, timeIntervals.sevenDays, timeIntervals.oneDay),
                  thirtyDays: computePrices(allSalesData, timeIntervals.thirtyDays, timeIntervals.sevenDays)
              };
  
              setPrices(priceResults);
          } catch (err) {
              setError('An error occurred while fetching the data.');
              console.error(err);
          }
  
          setIsLoading(false);
      };
  
      const getImageUrl = (template) => {
          return template && template.immutable_data && template.immutable_data.img
              ? `${IMAGE_BASE_URL}${template.immutable_data.img}`
              : 'placeholder.png'; // Add placeholder image
      };
  
      return (
          <div style={{ textAlign: 'center' }}>
              <h2>Sale Price Lookup</h2>
              <input
                  type="text"
                  placeholder="Enter Asset Template ID"
                  value={templateId}
                  onChange={handleTemplateIdChange}
              />
              <button onClick={fetchPrices} disabled={!templateId || isLoading}>Fetch Sale Prices</button>
              {isLoading && <p>Loading...</p>}
              {error && <p>Error: {error}</p>}
              {!isLoading && !error && prices.sixHours && (
                  <div>
                      <h3>{templateData ? templateData.name : 'Unknown Asset'}</h3>
                      {templateData && templateData.immutable_data && templateData.immutable_data.img && (
                          <img src={getImageUrl(templateData)} alt={templateData.name || 'Unknown Asset'} width="100" />
                      )}
                      <p>Past 6 hours: Min Price: {prices.sixHours.min?.toFixed(4)} WAX, Max Price: {prices.sixHours.max?.toFixed(4)} WAX</p>
                      <p>Past 7-24 hours ago: Min Price: {prices.oneDay.min?.toFixed(4)} WAX, Max Price: {prices.oneDay.max?.toFixed(4)} WAX</p>
                      <p>2-7 days ago: Min Price: {prices.sevenDays.min?.toFixed(4)} WAX, Max Price: {prices.sevenDays.max?.toFixed(4)} WAX</p>
                      <p>8-30 days ago: Min Price: {prices.thirtyDays.min?.toFixed(4)} WAX, Max Price: {prices.thirtyDays.max?.toFixed(4)} WAX</p>
                  </div>
              )}
          </div>
      );
  }
  
  export default FloorPriceLookup;